import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div style={{ height: "100vh" }}>
      <h1>Privacy Policy</h1>
      <ul style={{ listStyle: "none" }}>
        <li></li>
        <li>
          <p>
            The iffy app does not collect personal identifiable data (PII). It
            does use your device's IDFA ("Identifier for Advertisers"), but only
            for conversion attribution and in-app metrics tracking. Also, the
            URL of the media item being collected is stored, but it's not
            attributed to users.
          </p>
        </li>
        <li>
          The iffy website does not store or collect any personal data but uses
          Google Analytics for metrics tracking.
        </li>
        <li>This privacy policy is subject to change at any time.</li>
      </ul>
      <p>Last updated: 11/18/2020</p>
    </div>
  </Layout>
)

export default SecondPage
